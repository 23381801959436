/**
 * @prettier
 */
import styled from 'styled-components'
import { UIModal, UIButton, H3, BodyM } from 'talkable-ui-kit'

import { goToTwoFactorModal } from 'utils/etc'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

interface TwoFactorNotificationModalProps {
  default_setup_created: boolean
  force_show_modal: boolean
  show_tfa_notification: boolean
  staff_member: boolean
  tfa_enabled: boolean
  tfa_required: boolean
}

interface TwoFactorNotificationModalState {
  preventShowModal: boolean
  showTfaNotificationModal: boolean
}

export class TwoFactorNotificationModal extends React.PureComponent<
  TwoFactorNotificationModalProps,
  TwoFactorNotificationModalState
> {
  constructor(props: TwoFactorNotificationModalProps) {
    super(props)

    this.state = {
      preventShowModal: !props.default_setup_created,
      showTfaNotificationModal:
        !props.tfa_enabled &&
        (props.show_tfa_notification || props.tfa_required || props.force_show_modal),
    }
  }

  componentDidMount(): void {
    window.addEventListener('unPreventShowingTFA', this.handleUpdate)
  }

  componentWillUnmount(): void {
    window.removeEventListener('unPreventShowingTFA', this.handleUpdate)
  }

  handleUpdate = (): void => {
    this.setState({ preventShowModal: false })
  }

  hideTfaNotificationModal = (): void => {
    this.setState({
      showTfaNotificationModal: false,
    })
  }

  hideTfaNotificationForUser = async (): Promise<void> => {
    try {
      await $.ajax({
        type: 'PUT',
        url: Routes.internal_user_path(),
        data: {
          user: {
            show_tfa_notification: false,
          },
        },
        xhrFields: { withCredentials: true },
      })
    } catch (xhr) {
      notifyDevelopers({
        name: `Unable to patch current_user show_tfa_notification`,
        message: 'Ajax failure',
        xhr,
      })
      window.location.reload()
    }
  }

  onCloseModal = async (): Promise<void> => {
    await this.hideTfaNotificationForUser()
    this.hideTfaNotificationModal()
  }

  setupTFA = async (): Promise<void> => {
    await this.hideTfaNotificationForUser()
    goToTwoFactorModal()
  }

  render(): JSX.Element | null {
    const { showTfaNotificationModal, preventShowModal } = this.state
    const { staff_member, tfa_required } = this.props

    if (!preventShowModal && showTfaNotificationModal) {
      return (
        <UIModal position="center" actionClose={this.onCloseModal} width="450px">
          <Wrap data-testid="ac-tfa-notification-modal-wrap">
            <H3 className="mtm mbm">👮 Two-factor authentication is here!</H3>
            {staff_member || tfa_required ? (
              <div className="mbm ac-tfa-notification-modal-description-for-staff">
                <BodyM className="mbs">
                  Please enable 2FA on your device that you will use to log into Talkable.
                </BodyM>
              </div>
            ) : (
              <div className="mbm ac-tfa-notification-modal-description">
                <BodyM className="mbs">
                  We suggest you enable two-factor authentication on your device that you
                </BodyM>
                <BodyM>
                  will use to log into Talkable securely. As a result, your data will
                  always be safe.
                </BodyM>
              </div>
            )}
            <UIButton trait="success" width="190px" onClick={this.setupTFA}>
              Set up 2FA now
            </UIButton>
          </Wrap>
        </UIModal>
      )
    }

    return null
  }
}
